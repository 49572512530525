<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="商家類型" prop="merchantType" >
      </a-form-model-item>
      <a-form-model-item label="认证類型" prop="certificationType" >
      </a-form-model-item>
      <a-form-model-item label="法人姓名" prop="legalRealName" >
        <a-input v-model="form.legalRealName" placeholder="请输入法人姓名" />
      </a-form-model-item>
      <a-form-model-item label="法人联系方式" prop="legalMobile" >
        <a-input v-model="form.legalMobile" placeholder="请输入法人联系方式" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证" prop="legalCard" >
        <a-input v-model="form.legalCard" placeholder="请输入法人身份证" />
      </a-form-model-item>
      <a-form-model-item label="身份证-人像面" prop="portraitPlane" >
        <a-input v-model="form.portraitPlane" placeholder="请输入身份证-人像面" />
      </a-form-model-item>
      <a-form-model-item label="身份证-国徽面" prop="emblemPlane" >
        <a-input v-model="form.emblemPlane" placeholder="请输入身份证-国徽面" />
      </a-form-model-item>
      <a-form-model-item label="营业执照" prop="businessLicense" >
        <a-input v-model="form.businessLicense" placeholder="请输入营业执照" />
      </a-form-model-item>
      <a-form-model-item label="公司名稱" prop="companyName" >
        <a-input v-model="form.companyName" placeholder="请输入公司名稱" />
      </a-form-model-item>
      <a-form-model-item label="统一社会信用码" prop="socialCredit" >
        <a-input v-model="form.socialCredit" placeholder="请输入统一社会信用码" />
      </a-form-model-item>
      <a-form-model-item label="法定经营范围" prop="businessScope" >
        <a-input v-model="form.businessScope" placeholder="请输入法定经营范围" />
      </a-form-model-item>
      <a-form-model-item label="用戶编号" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用戶编号" />
      </a-form-model-item>
      <a-form-model-item label="申请狀態" prop="applicationStatus" >
      </a-form-model-item>
      <a-form-model-item label="拒绝原因" prop="refusalCause" >
        <a-input v-model="form.refusalCause" placeholder="请输入拒绝原因" />
      </a-form-model-item>
      <a-form-model-item label="店铺名稱" prop="storeName" >
        <a-input v-model="form.storeName" placeholder="请输入店铺名稱" />
      </a-form-model-item>
      <a-form-model-item label="店铺logo" prop="storeLogo" >
        <a-input v-model="form.storeLogo" placeholder="请输入店铺logo" />
      </a-form-model-item>
      <a-form-model-item label="店铺背景" prop="storeBackground" >
        <a-input v-model="form.storeBackground" placeholder="请输入店铺背景" />
      </a-form-model-item>
      <a-form-model-item label="店铺行业" prop="typeId" >
        <a-input v-model="form.typeId" placeholder="请输入店铺行业" />
      </a-form-model-item>
      <a-form-model-item label="套餐编号" prop="classId" >
        <a-input v-model="form.classId" placeholder="请输入套餐编号" />
      </a-form-model-item>
      <a-form-model-item label="保证金" prop="integralBail" >
        <a-input v-model="form.integralBail" placeholder="请输入保证金" />
      </a-form-model-item>
      <a-form-model-item label="服务费" prop="integralServe" >
        <a-input v-model="form.integralServe" placeholder="请输入服务费" />
      </a-form-model-item>
      <a-form-model-item label="店铺-国家" prop="storeCountry" >
        <a-input v-model="form.storeCountry" placeholder="请输入店铺-国家" />
      </a-form-model-item>
      <a-form-model-item label="店铺-地址省" prop="storeProvinceName" >
        <a-input v-model="form.storeProvinceName" placeholder="请输入店铺-地址省" />
      </a-form-model-item>
      <a-form-model-item label="店铺-地址市" prop="storeCityName" >
        <a-input v-model="form.storeCityName" placeholder="请输入店铺-地址市" />
      </a-form-model-item>
      <a-form-model-item label="店铺-地址区" prop="storeCountyName" >
        <a-input v-model="form.storeCountyName" placeholder="请输入店铺-地址区" />
      </a-form-model-item>
      <a-form-model-item label="店铺-详细地址" prop="storeDetailedAddress" >
        <a-input v-model="form.storeDetailedAddress" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="地址经度" prop="storeLongitude" >
        <a-input v-model="form.storeLongitude" placeholder="请输入地址经度" />
      </a-form-model-item>
      <a-form-model-item label="地址维度" prop="storeDimensionality" >
        <a-input v-model="form.storeDimensionality" placeholder="请输入地址维度" />
      </a-form-model-item>
      <a-form-model-item label="公司-国家" prop="companyCountry" >
        <a-input v-model="form.companyCountry" placeholder="请输入公司-国家" />
      </a-form-model-item>
      <a-form-model-item label="公司-地址省" prop="companyProvinceName" >
        <a-input v-model="form.companyProvinceName" placeholder="请输入公司-地址省" />
      </a-form-model-item>
      <a-form-model-item label="公司-地址市" prop="companyCityName" >
        <a-input v-model="form.companyCityName" placeholder="请输入公司-地址市" />
      </a-form-model-item>
      <a-form-model-item label="公司-地址区" prop="companyCountyName" >
        <a-input v-model="form.companyCountyName" placeholder="请输入公司-地址区" />
      </a-form-model-item>
      <a-form-model-item label="公司-详细地址" prop="companyDetailedAddress" >
        <a-input v-model="form.companyDetailedAddress" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="公司-地址经度" prop="companyLongitude" >
        <a-input v-model="form.companyLongitude" placeholder="请输入公司-地址经度" />
      </a-form-model-item>
      <a-form-model-item label="公司-地址维度" prop="companyDimensionality" >
        <a-input v-model="form.companyDimensionality" placeholder="请输入公司-地址维度" />
      </a-form-model-item>
      <a-form-model-item label="公司-详细地址" prop="companyAddress" >
        <a-input v-model="form.companyAddress" placeholder="请输入公司-详细地址" />
      </a-form-model-item>
      <a-form-model-item label="店铺-简介" prop="storeDetail" >
        <a-input v-model="form.storeDetail" placeholder="请输入店铺-简介" />
      </a-form-model-item>
      <a-form-model-item label="营业开始時間" prop="openStartTime" >
        <a-input v-model="form.openStartTime" placeholder="请输入营业开始時間" />
      </a-form-model-item>
      <a-form-model-item label="营业结束時間" prop="openEndTime" >
        <a-input v-model="form.openEndTime" placeholder="请输入营业结束時間" />
      </a-form-model-item>
      <a-form-model-item label="店铺-客服电话" prop="storeCall" >
        <a-input v-model="form.storeCall" placeholder="请输入店铺-客服电话" />
      </a-form-model-item>
      <a-form-model-item label="店铺-负责人" prop="storePrincipal" >
        <a-input v-model="form.storePrincipal" placeholder="请输入店铺-负责人" />
      </a-form-model-item>
      <a-form-model-item label="店铺-负责人电话" prop="principalCall" >
        <a-input v-model="form.principalCall" placeholder="请输入店铺-负责人电话" />
      </a-form-model-item>
      <a-form-model-item label="店铺-门头照(多个逗号隔开)" prop="doorPicture" >
        <a-input v-model="form.doorPicture" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="星级" prop="starLevel" >
        <a-input v-model="form.starLevel" placeholder="请输入星级" />
      </a-form-model-item>
      <a-form-model-item label="備註" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="收藏人数" prop="collectionNum" >
        <a-input v-model="form.collectionNum" placeholder="请输入收藏人数" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStoreCertification, addStoreCertification, updateStoreCertification } from '@/api/store/storeCertification'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        merchantType: null,

        certificationType: null,

        legalRealName: null,

        legalMobile: null,

        legalCard: null,

        portraitPlane: null,

        emblemPlane: null,

        businessLicense: null,

        companyName: null,

        socialCredit: null,

        businessScope: null,

        userId: null,

        applicationStatus: 0,

        refusalCause: null,

        storeName: null,

        storeLogo: null,

        storeBackground: null,

        typeId: null,

        classId: null,

        integralBail: null,

        integralServe: null,

        storeCountry: null,

        storeProvinceName: null,

        storeCityName: null,

        storeCountyName: null,

        storeDetailedAddress: null,

        storeLongitude: null,

        storeDimensionality: null,

        companyCountry: null,

        companyProvinceName: null,

        companyCityName: null,

        companyCountyName: null,

        companyDetailedAddress: null,

        companyLongitude: null,

        companyDimensionality: null,

        companyAddress: null,

        storeDetail: null,

        openStartTime: null,

        openEndTime: null,

        storeCall: null,

        storePrincipal: null,

        principalCall: null,

        doorPicture: null,

        starLevel: null,

        createTime: null,

        remark: null,

        collectionNum: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        merchantType: null,
        certificationType: null,
        legalRealName: null,
        legalMobile: null,
        legalCard: null,
        portraitPlane: null,
        emblemPlane: null,
        businessLicense: null,
        companyName: null,
        socialCredit: null,
        businessScope: null,
        userId: null,
        applicationStatus: 0,
        refusalCause: null,
        storeName: null,
        storeLogo: null,
        storeBackground: null,
        typeId: null,
        classId: null,
        integralBail: null,
        integralServe: null,
        storeCountry: null,
        storeProvinceName: null,
        storeCityName: null,
        storeCountyName: null,
        storeDetailedAddress: null,
        storeLongitude: null,
        storeDimensionality: null,
        companyCountry: null,
        companyProvinceName: null,
        companyCityName: null,
        companyCountyName: null,
        companyDetailedAddress: null,
        companyLongitude: null,
        companyDimensionality: null,
        companyAddress: null,
        storeDetail: null,
        openStartTime: null,
        openEndTime: null,
        storeCall: null,
        storePrincipal: null,
        principalCall: null,
        doorPicture: null,
        starLevel: null,
        createTime: null,
        remark: null,
        collectionNum: null
      }
    /*  this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStoreCertification({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStoreCertification(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStoreCertification(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
